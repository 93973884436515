import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import DataObjectIcon from '@mui/icons-material/DataObject';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import WebIcon from '@mui/icons-material/Web';
import StorageIcon from '@mui/icons-material/Storage';
import BugReportIcon from '@mui/icons-material/BugReport';

function Main() {

  //Open link a new tab
  function hangleLink(e,page)
  {
    e.preventDefault();
    window.open(page, '_blank', 'noreferrer');
  }
  

  return (
    <Grid
      item
      xs={12}
      md={8}
    >
      <Typography variant="h5" gutterBottom>
      Work Experience
      </Typography>
      <Divider />
      <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
              <span>   <Typography variant='caption'>2018-2021 </Typography>  
              <Typography>Keywords Studios Montreal </Typography> 
              <Chip label="FQA" color="primary" size="small"/>
              </span>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         Worked as a functionality tester and became a level 3 tester Right before the senior tester position, but did not apply for the position since I had always planned to attend university. Also worked as a Certification Tester for the xbox platform for a few months. My responsabilities included: Reporting bugs, writing and running test cases, regressing issues, among other things.
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
          > 
          <IconButton color="primary" aria-label="upload picture" onClick={(e) => {hangleLink(e,"https://goo.gl/maps/qm38YEVQoa51KaGN7")}}>
          <DirectionsIcon fontSize="small"/>
            </IconButton>
            </Box>
          
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span>   <Typography variant='caption'>Summer 2022</Typography> 
           <Typography>Pole to Win Montreal </Typography> 
          <Chip label="Spanish LQA" color="primary" size="small"/>  {" "}   <Chip label="English LQA" color="primary" size="small"/>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Worked as a localization tester the entire summer, specifically in Spanish and English localization. My responsabilities included: Reporting linguistic bugs, running test cases, among other things.
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
          > 
          <IconButton color="primary" aria-label="upload picture" onClick={(e) => {hangleLink(e,"https://goo.gl/maps/Dkk8tGtShdwjs3d49")}}>
          <DirectionsIcon fontSize="small"/>
            </IconButton>
            </Box>
        </AccordionDetails>
      </Accordion>

    </div>
    <br ></br>
    <Typography variant="h5" gutterBottom>
     Relevant Skills
      </Typography>
      <Divider />

      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <DataObjectIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Experience with object-oriented programming languages" secondary="Java, C# and C++" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WebIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Front-end and Back-end Web programming" secondary="React, Javascript, PHP and Node.js" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <StorageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Server Managment and Could Computing" secondary="AWS and Digital Ocean" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <VideogameAssetIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Game Engine and a bit 3D Modeling" secondary="Unity and some experience animating in Blender" />
      </ListItem>
    </List>

    <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BugReportIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Bug Tracking Software and the Office suite" secondary="Jira, Test Rail and a lot of experience with Excel" />
      </ListItem>
    </Grid>
  );
}

export default Main;
