import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import LinearProgress from '@mui/material/LinearProgress';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

function Sidebar(props) {
  function hangleLink(e,page)
  {
    e.preventDefault();
    window.open(page, '_blank', 'noreferrer');
  }
  
  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.900' }}>
        <Typography variant="h6" gutterBottom>
        Education
        </Typography>
        <Divider />
        <Typography variant='caption'>2020-Today </Typography>  
        <Typography> Concordia University 
        <IconButton color="primary" aria-label="upload picture" onClick={(e) => {hangleLink(e,"https://www.concordia.ca/")}}>
          <LaunchIcon sx={{ fontSize: 15 }}/>
            </IconButton>
          </Typography> 
          <Typography>Software Engineering</Typography>
      </Paper>
    <br></br>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.900' }}>
        <Typography variant="h6" gutterBottom>
        Written and Spoken Languages
        </Typography>
        <Divider />
       
        <Typography>Spanish: Native</Typography>
        <LinearProgress variant="determinate" value={100} />
        <Typography>English: Advanced</Typography>
        <LinearProgress variant="determinate" value={100} />
        <Typography>French: Intermediate</Typography>
        <LinearProgress variant="determinate" value={75} />
      </Paper>
      <br></br>
      <Typography>
      <IconButton color="primary" aria-label="upload picture" onClick={(e) => {hangleLink(e,"https://github.com/ADGLx")}}>
          <GitHubIcon fontSize='Large'/>
            </IconButton>
            Github </Typography>

            <Typography>
      <IconButton color="primary" aria-label="upload picture" onClick={(e) => {hangleLink(e,"alvarodavidglx@hotmail.com")}}>
          <EmailIcon fontSize='Large'/>
            </IconButton>
            Email </Typography>
    </Grid>
  );
}

export default Sidebar;
