import * as React from 'react';
import { useState,useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Select, MenuItem, InputLabel, FormControl, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import Title from './Title';
import Axios from "axios";

//Dialog stuff 
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Generate Order Data
function createData(id, date, time, title, description, priority) {
  //handleRowsChange();//Calling this so it changes 
  return { id, date, time, title, description, priority };
}
// I should update rows basically
 const placeholder = [
  createData(
    0,
    '16 Mar, 2019',
    '3:00PM',
    'PLACEHOLDER TITLE 1',
    'placeholder description',
    "High",
  ),
  createData(
    1,
    '16 Mar, 2019',
    '3:00PM',
    'PLACEHOLDER TITLE 2',
    'placeholder description',
    "High",
  )
 ];

function preventDefault(event) {
  event.preventDefault();
}




export default function Orders() {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);

      //This just calls it everytime it is rendered
      useEffect(() => {
        handleReminderList();
       },[])

  function handleReminderList() 
  {
        //In here we get the list of the reminders so we just send a request to the server asking for all reminders
        //Create an objet with the user's info to make sure it is them
        const userToken =JSON.parse(localStorage.getItem('user-token'));
        //console.log("Sending this user token to get reminders: "+ userToken)
        Axios.post('/api/getReminders', userToken). 
        then (function (response) {
          //In here we style the data and add it to the date thingy
          //Restart the value of rows
    
          //Creating a new array and adding all the stuff to that one
          var newData = []
          //Wanna clean the rows before we check everytime
          response.data.forEach(reminder => {
            newData.push(createData(reminder['id'], reminder['date'], reminder['time'],reminder['title'] , reminder['description'], reminder['priority']))
           // setRows(rows => [...rows, createData(reminder['id'], reminder['date'], reminder['title'], reminder['description'], reminder['priority'])])
          });
          //console.log(newData)
          //console.log(rows)
          setRows(newData);
          
         // console.log(rows)//I dont get why this doesnt update but wtv
        });
    
  }
  
  function DeleteButton (prop) {
    //In the button we need the info about 
    function handleDelete(id) 
    {
      
        console.log("deleting reminder ID:"+ id)
        const jsonID = {
          ReminderID : id
        };
        
        Axios.post('/api/deleteReminder', jsonID).then (function (response) 
        {
          //In here we put message like 
          if(response)
          console.log("Reminder deleted!");
          setRows(rows.filter((row) => row.id !== id));
        });
    }
  
    return (
      <React.Fragment>
        <Button onClick={(e) => handleDelete(prop.id)}><DeleteForeverIcon/></Button>
      </React.Fragment>
    );
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendReminder = (event) => {
    event.preventDefault();//This was being called so it was hard

      const data = new FormData(event.currentTarget);
    //I will try to print it all here
    //console.log("Date and Time: " + data.get('date') + "\nTitle: "+data.get('title'));

    const sentObj = {
      userid:  JSON.parse(localStorage.getItem('user-token'))['id'],
      dateT: data.get('date').toString(),
      title: data.get('title'),
      description: data.get('description'),
      priority: data.get('priority')
    };
    const date = sentObj['dateT'].split(" ")[0];
    const time = sentObj['dateT'].split(" ")[1] + sentObj['dateT'].split(" ")[2];
    //So in here we just send the info to the server (So we just do axios)
    Axios.post('/api/setReminder', sentObj)
    .then ((response)=>{

      if(response.data === true)
      {
        //Just close and put a successful pop up
        console.log("Reminder successfully sent!")
        setOpen(false)
        //Also add logic here so it is updated right away
        setRows([...rows, createData("tempkey",date, time, sentObj['title'], sentObj['description'],sentObj['priority'])])
      }
      else {
        //keep open and make it show error 
        console.log("Something went wrong tho")
      }

    })

  };

  
  function RemindersForm (){
    const [datetime, setDatetime] = React.useState(dayjs());
    const [priority, setPriority]= React.useState('');

    const handlePriorityChange = (event) => {
      setPriority(event.target.value);
    }
    
    return (
      <div>

        <Dialog open={open} onClose={handleClose}>
          
          <DialogTitle>Add Reminder</DialogTitle>
          <form onSubmit={handleSendReminder}> 
          <DialogContent>
            <DialogContentText>
              The reminder will be sent to the user's email 15 minutes before the event occurs.
            </DialogContentText>
            <br/>
   {/* Date Time (Center this later) */}
   <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => <TextField name="date" {...props} />}
            label="Date and Time"
            value={datetime}
            onChange={(newValue) => {
              setDatetime(newValue);
            }}
          />
        </LocalizationProvider>
            <TextField
              autoFocus
              required
              margin="dense"
              name="title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
            />
              <TextField
              margin="dense"
              name='description'
              label="Description"
              type="text"
              fullWidth
              variant="standard"
            />  
        <br/>   <br/>
        <FormControl fullWidth>
        <InputLabel id="pLabel">Priority</InputLabel>
                <Select 
               labelId='pLabel'
               name = 'priority'
               value = {priority}
               label="Priority"
              onChange={handlePriorityChange}
                >
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" label="Submit">Submit</Button>
          </DialogActions>
          </form>
        </Dialog>
        
      </div>
    );
  }



  // function updateReminders() {

  //   setRows([
  //     createData(
  //       0,
  //       '1111',
  //       '3111',
  //       '111',
  //       '1111n',
  //       "111",
  //     )
  //   ])
  // }

  
  return (
    <React.Fragment>
      <Grid container spacing={2}> 
     <Grid item xs={10}>  <Title > All Reminders </Title> </Grid>
     <Grid item xs={2}><Button variant='contained'onClick={handleClickOpen}>Add Reminder</Button> </Grid>
         
         </Grid>
      {/* The reminders form*/}
       <RemindersForm />

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Time</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Priority</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.time}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell align="right">{row.priority}</TableCell>
              <TableCell align="right"><DeleteButton id= {row.id}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more reminders
      </Link>
      
    </React.Fragment>
  );
}


